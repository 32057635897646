<template>
  <div>
    <a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button type="primary">选择小程序用户</a-button>
					<span v-if="selectedRowKeys.length !== 0"> 已选{{selectedRowKeys.length}}小程序用户</span>
				</slot>
			</span>
      <a-modal v-model:visible="visible" width="850px" title="小程序用户" :maskClosable="false" @cancel="onCancel" @ok="onOk">
        <a-spin :spinning="loading">
          <div>
            <a-form ref="form" name="form" :model="formState" @finish="onSearch">
              <a-row>
                <a-col :span="18">
					<a-row>
						<a-form-item style="margin-right: 10px;" label="用户名称" name="nickname">
						  <a-input placeholder="请输入用户名称" v-model:value="formState.nickname" />
						</a-form-item>
						<a-form-item label="用户电话" name="phone">
						  <a-input placeholder="请输入用户电话" v-model:value="formState.phone" />
						</a-form-item>
					</a-row>
                </a-col>
                <a-col :span="6" style="text-align: right;">
                  <a-button type="primary" html-type="submit">搜索</a-button>
                  <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
                </a-col>
              </a-row>
            </a-form>

            <div>
              <a-table :columns="columns" :dataSource="dataList" rowKey="id" :pagination="pagination"
                       :rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
                       :scroll="{ x: 500 }">
              </a-table>
            </div>
          </div>
        </a-spin>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {getMemberList} from "@/service/modules/member";
export default {
  model: {
    prop: 'value'
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
	selectItems: {
		type: Array,
		default: () => {
		  return []
		}
	},
    type: { // checkbox or radio
      type: String,
      default: 'checkbox'
    },
    id: { // 影院ID
      type: null,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    id(newVal) {
      this.pagination.current = 1;
      //this.pagination.pageSize = 10;
      this.getData();
    },
    value(newVal) {
      this.selectedRowKeys = newVal;
    }
  },
  created() {
    this.selectedRowKeys = this.value;
  },
  data() {
    return {
      formState: {},
      targetKeys: [],
      loading: false,
      visible: false,
      selectedRowKeys: [], // 选择的id集合
      selectedListData: [], // 选择的数据集合
      selectedListIdDataMap: {}, // 每选中一项时的数据对比字典，防止分页后数据丢失
      classifyList: [],
      dataList: [],
      columns: [{
        title: '用户名称',
        dataIndex: 'nickname'
      }, {
        title: '用户电话',
        dataIndex: 'phone'
      }],
      modelRef: {},
      searchData: {},
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        let ret = await getMemberList({
          ...this.searchData
        });
        this.loading = false;
        if (ret.code === 200) {
          this.pagination.total = ret.data.totalCount;
          this.dataList = ret.data.list;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onShowModal() {
      if (this.disabled) return;
      this.getData();
      this.visible = true;
      this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
	  this.selectedListData = JSON.parse(JSON.stringify(this.selectItems));
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    onSelectChange(record, selected) {
      if (this.type === 'checkbox') {
        if (selected) {
          this.selectedListData.push(JSON.parse(JSON.stringify(record)));
          this.selectedRowKeys.push(record.id);
        } else {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
          this.selectedListData = this.selectedListData.filter(item => {
            return item.id !== record.id;
          });
        }
      } else {
		  if (selected) {
		  	this.selectedListData = [JSON.parse(JSON.stringify(record))];
		  	this.selectedRowKeys = [record.id];
		  } else {
		  	this.selectedListData = [JSON.parse(JSON.stringify(record))];
		  	this.selectedRowKeys = [];
		  }
	  }
    },
    onSelectAll(selected) {
      if (selected) {
        this.dataList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) === -1) {
            this.selectedListData.push(JSON.parse(JSON.stringify(item)));
            this.selectedRowKeys.push(item.id);
          }
        })
      } else {
        this.dataList.forEach(item => {
          if (this.selectedRowKeys.indexOf(item.id) !== -1) {
            this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
          }
        })
      }
    },
    resetBtn() {
      this.$refs.formRef.resetFields();
      this.onSearch();
    },
    onCancel() {
      this.visible = false;
      this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
	  this.selectedListData = JSON.parse(JSON.stringify(this.selectItems));
    },
    onOk() {
      this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
      this.$emit('change', JSON.parse(JSON.stringify(this.selectedListData)));
      this.visible = false;
    },
    onChange(e) {
      this.selectedRowKeys = [];
    }
  }
}
</script>

<style scoped>
.ui-form-item {
  margin-right: 20px;
}
</style>
